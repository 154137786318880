import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_gameit from "../Media/Documentation/assets/img/logo_gameit.png";
import { Modal } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/ModalStyles.css";
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
  integrity="sha384-rqZRzFyYJAAc2KN8bOe3W5p5YBx9K3l5B0f5g5F5w5M5b5F5G5I5U5Q5T5F5D5E5"
  crossorigin="anonymous"
/>


const Header = (data) => {
  //To go on other page
  const navigate = useNavigate();

  //State to store search results
  const [searchList, setSearchList] = useState([]);

  //SHOW MODAL
  const [modalShow, setmodalShow] = useState(false);
  //Method to show entered text
  const handleSearch = (search) => {
    // console.log("search is ",search);
    // console.log("data is ",data);

    let arr = [];
    data.data.forEach((e) => {
      e.game.forEach((f) => {
        // console.log("f ",f.name);

        if (
          search !== "" &&
          search !== " " &&
          (f.name.toLowerCase().startsWith(search) || f.name.startsWith(search))
        ) {
          // console.log(f.name);
          arr.push(f.name);
        }
      });
    });
    // console.log("arry is ",arr);
    setSearchList(arr);
  };

  //Method to play that search game by its name
  const handleSearchPlay = (name) => {
    // console.log("game name is ",name);

    //Get Selected Game
    data.data.forEach((e) => {
      e.game.forEach((f) => {
        if (f.name === name) {
          // console.log(f.game);
          window.location.href = f.game;
        }
      });
    });
  };
  const showModal = () => {
    setmodalShow(true);
    console.log("calling");
  };

  const handleModalClose = () => {
    setmodalShow(false);
  };

  //-------Winners Modal-----------

  const [showWinnersModal, setShowWinnersModal] = useState(false);
  const [winners, setWinners] = useState([
    { id: 1, name: "9896xxxxxx", score: 1, time: "05:15:30" },
    { id: 2, name: "7937xxxxxx", score: 2, time: "04:50:20" },
    { id: 3, name: "8633xxxxxx", score: 3, time: "04:25:40" },
    { id: 4, name: "3544xxxxxx", score: 4, time: "04:05:55" },
    { id: 5, name: "0834xxxxxx", score: 5, time: "03:30:05" },
    { id: 6, name: "1834xxxxxx", score: 6, time: "03:10:20" },
    { id: 7, name: "0935xxxxxx", score: 7, time: "01:50:10" },
    { id: 8, name: "9835xxxxxx", score: 8, time: "01:30:10" },
    { id: 9, name: "3354xxxxxx", score: 9, time: "01:10:10" },
    { id: 10, name: "3509xxxxxx", score: 10,time: "01:05:10"},
  ]);

  const handleClose = () => setShowWinnersModal(false);
  const handleShow = () => {
    console.log("calling handleShow");
    setShowWinnersModal(true);
  };

  return (
    <>
      <header>
        <div id="sticky-header" className="main-header">
          <div className="container-fluid container-full-padding">
            <div className="row">
              <div className="col-12">
                <div className="main-menu">
                  <nav>
                    <div className="logo">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        <img
                          src={logo_gameit}
                          //   src="../Documentation/assets/img/logo_gameit.png"
                          alt="Logo"
                        />
                      </span>
                    </div>
                    <div
                      id="mobile-menu"
                      className="navbar-wrap d-none d-lg-flex"
                    >
                      <ul>
                        <li className="show">
                          <a href="#Sports">DES SPORTS</a>
                        </li>
                        <li>
                          <a href="#Funny">DRÔLE</a>
                        </li>
                        <li>
                          <a href="#Action">ACTION</a>
                        </li>
                        <li>
                          <a href="#Adventure">AVENTURE</a>
                        </li>
                        <li>
                          <a href="#Racing">COURSES</a>
                        </li>
                        <li>
                          <a href="#Kids">ENFANTS</a>
                        </li>
                        <li>
                          <a href="#Arcade">PUZZLE</a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            GAGNANTS
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-action">
                      <ul>
                        <li className="header-search">
                          <a
                            data-toggle="modal"
                            data-target="#search-modal"
                            href="/"
                          >
                            <i className="fas fa-search"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="cus-m-btn">
                        <a
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            GAGNANTS
                          </a>
                    </div>
                  </nav>
                </div>
                <div className="mobile-menu"></div>
              </div>
              {/* <!-- Modal Search --> */}
              <div
                className="modal fade"
                id="search-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form>
                      <input
                        type="text"
                        placeholder="Search here..."
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                      <button>
                        <i className="fa fa-search"></i>
                      </button>
                      <div className="search-main-div">
                        {searchList.length > 0 &&
                          searchList.map((val, key) => {
                            // console.log("v ",val);
                            return (
                              <div key={key} className="search-div">
                                <span
                                  className="search-span"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleSearchPlay(val);
                                  }}
                                >
                                  {val}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal centered show={modalShow} onHide={handleClose}>
        <div class="c-main-box">
        <Modal.Header closeButton>
          <div class="c-box">
            <Modal.Title>Game 360</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body >
                      This is Data
        </Modal.Body>
        </div>
        </Modal> */}

        <Modal centered show={showWinnersModal} onHide={handleClose}>
          <div className="custom-modal">
            <h2 className="modal-title">TOP 10 DES GAGNANTS
            <i className="fas fa-crown" style={{ marginLeft: '10px' }}></i>
            </h2>
            
            <ul className="modal-list">
              <li className="colorful-list-item">
                <span>PLAYER</span>
                <span className="rank-column">RANG</span>
                <span>TEMPS PASSÉ</span>
              </li>
              {winners.map((winner) => (
                <li key={winner.id} className="colorful-list-item">
                  <span>{winner.name}</span>
                  <span>{winner.score}</span>
                  <span>{winner.time}</span>
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      </header>
    </>
  );
};
export default Header;
