import React from "react";
import { useNavigate } from "react-router-dom";

const SectionOne = (data) => {

  //To go on any other page
  const navigate=useNavigate();

  //To handle play games
  const handlePlayGames=()=>{
    data.data.forEach(e=>{
      if(e.category==='mostPlayed')
      {
        // console.log("selected ",e.game);
        localStorage.setItem("games",JSON.stringify(e.game));
        navigate("/seeAll");
      }
    })
  }

  return (
    <section className="slider-area slider-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9">
            <div className="slider-content text-center">
              <h6 className="wow fadeInDown" data-wow-delay=".2s">
              JEUX
              </h6>
              <h2 className="tlt fix" data-in-effect="fadeInLeft">
                UV <span>JEUX</span>
              </h2>
              <p className="wow fadeInUp" data-wow-delay="2s">
              TROUVEZ DE NOUVEAUX JEUX BASÉS SUR DE NOUVELLES TECHNOLOGIES PLEINS DE PLAISIR,
              DE DIVERTISSEMENT ET DE CONNAISSANCES.
              </p>
              <span className="btn wow fadeInUp" data-wow-delay="2.2s"
                onClick={()=>{
                  handlePlayGames();
                }}>
                JOUER À DES JEUX
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionOne;